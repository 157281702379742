<template>
    <Header :isShow="isShow" />
    <div class="warper" >
        <!-- <NewsTop :activeIndex="0" /> -->
        <div class="content_box" ref="header">
            <div class="third max_small_box">
                <div class="text" v-for="(item, index) in thirdList" :key="index" @click="handleChangeThird(index)">
                    <p :class="thirdIndex == index ? 'active' :''">{{item.name}}</p>
                </div>
            </div>
            <div class="content max_small_box" >
                <div class="first" v-for="(item, index) in firstList" :key="index" data-aos="fade-up">
                    <router-link :to="`/news/news_detail/${item.id}&${page}`">
                        <div class="img">
                            <img v-if="item.image == '' || item.image == null" src="@/assets/images/a900a965ed5d1453b71abb6d303d0ab.jpg" alt="" />
                            <img v-else :src="imgUrl + item.image" alt="" />
                        </div>
                        <div class="text">
                            <p class="time" >{{item.releasetime.split(' ')[0]}}</p>
                            <p class="title">{{item.title}}</p>
                            <p class="desc">{{item.intro}}</p>
                            <p class="more">查看更多</p>
                        </div>
                    </router-link>
                </div>
                <div class="line"></div>
                <div class="second" v-if="list">
                    <div class="list" v-for="(item, index) in list" :key="index" data-aos="fade-up">
                        <router-link :to="`/news/news_detail/${item.id}&${page}`">
                            <div class="img">
                                <img v-if="item.image == '' || item.image == null" src="@/assets/images/a900a965ed5d1453b71abb6d303d0ab.jpg" alt="" />
                                <img v-else :src="imgUrl + item.image" alt="" />
                            </div>
                            <div class="text">
                                <p class="time">{{item.releasetime.split(' ')[0]}}</p>
                                <p class="title">{{item.title}}</p>
                                <p class="desc" v-if="item.intro">{{item.intro}}</p>
                                <p class="more">查看更多<span class="iconfont">&#xe618;</span></p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination max_small_box">
            <a-pagination v-model:current="page" :pageSize="4" :total="total_page" @change="handleChangePage" showQuickJumper />
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, watchEffect, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import NewsTop from '../../components/news/NewsTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//列表处理函数
const newsListEffect = (route, header) => {

    //列表
    const firstList = ref()

    //列表
    const data = reactive({list:{}})

    //页数
    const page = ref(1)

    //总页数
    const total_page = ref()

    const now_id = ref()

    const thirdList = ref()

    const thirdIndex = ref(0)

    //获取三级
    const getThirdNav = async (id, page_id) => {
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
        }
        await post('/nav/get_third_nav', postData).then((res) => {
            // console.log('三级导航', res)
            thirdList.value = res.data
            getList(route.params.id, route.params.page_id)
        })
    }

    //获取详情
    const getList = async (id, page_id) => {
        if(now_id.value == id){
        }else{
            page.value = 1
            now_id.value = id
        }
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
            page: page.value,
        }
        let topData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
        }
        var topNews = ''
        if(thirdList.value.length != 0) {
            postData.third_navigation_id = thirdList.value[thirdIndex.value].id
            topData.third_navigation_id = thirdList.value[thirdIndex.value].id
        }
        await post('/news/get_news_top', topData).then((resTop) => {
            // console.log('获取置顶', resTop)
            if(resTop.data.length != 0) {
                // console.log(11111111111111111)
                topNews = resTop.data[0]
            }
        })
        await post('/news/get_news', postData).then((res) => {2
            // console.log('新闻列表', res)
            if(topNews.length != 0) {
                // console.log(topNews)
                res.data.data.unshift(topNews)
            }
            firstList.value = res.data.data.slice(0,1)
            data.list = res.data.data.slice(1,100)
            total_page.value = res.data.total
        })
        
    }

    //跟换三级
    const handleChangeThird = (index) => {
        page.value = 1
        thirdIndex.value = index
        getList(route.params.id, route.params.page_id)
    }   

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
        getList(route.params.id, route.params.page_id)
        document.documentElement.scrollTop = document.body.scrollTop = header.value.offsetTop - 20;
    }

    watchEffect(() =>{
        // getList(route.params.id, route.params.page_id)
        getThirdNav(route.params.id, route.params.page_id)
    })

    const { list } = toRefs(data)

    return { firstList, list, getList, page, total_page, handleChangePage, thirdList, thirdIndex, handleChangeThird }
}


export default {
    name: 'NewsCenter',
    components: { Header, Footer, FloatingFrame, NewsTop },
    setup() {

        const  route = useRoute()

        const header = ref(null)

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //列表处理函数
        const { firstList, list, getList, page, total_page, handleChangePage, thirdList, thirdIndex, handleChangeThird } = newsListEffect(route, header)

        //获取滑块到顶部的距离
        

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
            
        });

        // getList(route.params.id, route.params.page_id)

        const { imgUrl }  = api

        return { imgUrl, isShow, header, firstList, list, page, total_page, handleChangePage, thirdList, thirdIndex, handleChangeThird }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.content_box{
    margin-top: 40px;
    @media screen and (max-width: 1200px){
        margin-top: 20px;
    }
}
.third{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .text{
        width: 100%;
        margin-right: 2%;
        text-align: center;
        cursor: pointer;
        &:last-child{
            margin-right: 0%;
        }
        p{
            display: block;
            font-size: 16px;
            color: #666666;
            padding: 12px 0;
            border: 1px solid #dcdcdc;
            margin: 10px 0;
        }
        .active{
            color: #1f2c5c;
            border: 1px solid #1f2c5c;
        }
    }
}
.content{
    .first{
        cursor: pointer;
        &:hover .text .more{
            transform: scale(1.1);
        }
        a{
            display: flex;
             @media screen and (max-width: 1200px){
                padding: 0 30px;
            }
            @media screen and (max-width: 1200px){
                display: block;
            }
            .img{
                width: 30%;
                height: 200px;
                padding: 2%;
                @media screen and (max-width: 1200px){
                    width: 100%;
                    height: 200px;
                    padding: 0%;
                }
                @media screen and (max-width: 900px){
                    height: 250px;
                    padding: 0%;
                }
                @media screen and (max-width: 600px){
                    height: 180px;
                    padding: 0%;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .text{
                width: 48%;
                margin-left: 4%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media screen and (max-width: 1200px){
                    width: 100%;
                    margin-top: 10px;
                    margin-left: 0%;
                }
                .time{
                    color: rgba(16, 16, 16, 100);
                    font-size: 18px;
                    font-weight: 600;
                    @media screen and (max-width: 900px){
                        font-size: .16rem;
                    }
                }
                .title{
                    color: rgba(47, 80, 158, 100);
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 20px;
                    line-height: 30px;
                    transition: .3s all;
                    @media screen and (max-width: 900px){
                        font-size: .16rem;
                        margin-top: 8px;
                    }
                }
                .desc{
                    color: rgba(16, 16, 16, 100);
                    font-size: 18px;
                    margin-top: 20px;
                    line-height: 20px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                        margin-top: 8px;
                    }
                }
                .more{
                    color: rgba(255, 255, 255, 100);
                    font-size: 16px;
                    background-color: #2F509E;
                    margin-top: 20px;
                    width: max-content;
                    padding: 10px 15px;
                    border-radius: 10px;
                    transition: .3s all;
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                        margin-top: 8px;
                    }
                }
            }
        }
    }
    .line{
        width: 100%;
        height: 2px;
        background-color: #bbb;
        margin-top: 40px;
    }
    .second{
        margin-top: 40px;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        .list{
            cursor: pointer;
            transition: .3s all;
            margin-bottom: 30px;
            &:hover{
                background: $content-backgroundcolor;
                .time{
                    color: #fff;
                }
            }
            &:hover .text .time{
                color: #fff;
            }
            &:hover .text .title{
                color: #fff;
            }
            &:hover .text .desc{
                color: #fff;
            }
            &:hover .text .more{
                color: #fff;
            }
            a{
                display: flex;
                @media screen and (max-width: 1200px){
                    display: block;
                }
                .img{
                    width: 20%;
                    height: 200px;
                    padding: 2%;
                    @media screen and (max-width: 1200px){
                        width: 100%;
                        height: 200px;
                        padding: 0%;
                    }
                    @media screen and (max-width: 900px){
                        height: 250px;
                        padding: 0%;
                    }
                    @media screen and (max-width: 600px){
                        height: 180px;
                        padding: 0%;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .text{
                    width: 63%;
                    margin-left: 4%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    @media screen and (max-width: 1200px){
                        width: 100%;
                        margin-top: 10px;
                        margin-left: 0%;
                    }
                    .time{
                        color: #A4A4A4;
                        font-size: 16px;
                        font-weight: 600;
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                        }
                    }
                    .title{
                        color: #101010;
                        font-size: 18px;
                        font-weight: 600;
                        margin-top: 20px;
                        line-height: 30px;
                        @media screen and (max-width: 900px){
                            font-size: .16rem;
                            margin-top: 8px;
                        }
                    }
                    .desc{
                        color: rgba(16, 16, 16, 100);
                        font-size: 16px;
                        margin-top: 20px;
                        line-height: 20px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                            margin-top: 8px;
                        }
                    }
                    .more{
                        color: #101010;
                        font-size: 16px;
                        margin-top: 20px;
                        width: max-content;
                        border-radius: 10px;
                        cursor: pointer;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                            margin-top: 8px;
                        }
                        span{
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }
}
.pagination{
    text-align: center;
    padding-top: 80px;
    display: block;
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>